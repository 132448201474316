<template>
  <button
    :type="nativeType"
    @click="onClick"
    :class="[
      'zm-button',
      type ? 'zm-button--' + type : '',
      buttonSize ? 'zm-button--' + buttonSize : 'zm-button--',
      {
        'is-round': round,
        'is-circle': circle,
        'is-disable': disabled,
        'is-plain': plain,
      },
    ]"
    :style="style"
  >
    <slot></slot>
  </button>
</template>

<script lang="ts">
/**
 *  1. 原生类型 nativeType
 *  2. 按钮类型 type
 *  3. 禁止点击 （loading）
 *  4. 尺寸
 *  5. 按钮外形 （默认无圆角、有圆角、圆形）
 *  6. 点击方法
 */
import { defineComponent } from "vue";
import useButton from "./button";
export default defineComponent({
  name: "ZmButtom",
  emits: ["handleclick"],
  props: {
    nativeType: {
      type: String,
      default: "Button",
    },
    plain: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
    round: {
      type: Boolean,
      default: false,
    },
    circle: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "normal",
    },
    style: {
      type: Object,
      default: ()=>{
        return {}
      },
    },
  },
  setup(props, ctx) {
    const { buttonSize } = useButton(props);
    function onClick() {
      ctx.emit("handleclick");
    }
    return {
      buttonSize,
      onClick,
    };
  },
});
</script>

<style scoped>
@import url("./button.css");
</style>